.chat-container {
    width: 400px;
    height: 600px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    background-color: #1d6cc1;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .status-online {
    color: #00e676;
  }
  
  .chat-body {
    flex: 1;
    padding: 10px;
    background-color: #f5f5f5;
    overflow-y: auto;
  }
  
  .chat-footer {
    padding: 10px;
    display: flex;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .user-message {
    text-align: right;
  }
  
  .bot-message {
    text-align: left;
  }
  
  .message-text {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: #e0e0e0;
  }
  
  .user-message .message-text {
    background-color: #2196f3;
    color: white;
  }
  
  .message-timestamp {
    font-size: 0.8em;
    color: gray;
    margin-top: 5px;
  }
  