/* CreateUserModal.css */
.modal-content {
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .form-control {
    font-size: 16px;
    border-radius: 5px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  