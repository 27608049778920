/* Fade-in animation */
.fade-in {
    opacity: 0;
    animation: fadeIn 4s forwards;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Slide-in-left animation */
  .slide-in-left {
    transform: translateX(-100%);
    animation: slideInLeft 0.5s forwards;
  }
  
  @keyframes slideInLeft {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }

  .bounce {
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }

  .zoom-in {
    transform: scale(0.5);
    animation: zoomIn 2s forwards;
  }
  
  @keyframes zoomIn {
    0% { transform: scale(0.5); }
    100% { transform: scale(1); }
  }

  .glitch {
    position: relative;
    animation: glitch 1s infinite;
  }
  
  @keyframes glitch {
    0% { transform: translate(0); }
    10% { transform: translate(-2px, 2px); }
    20% { transform: translate(2px, -2px); }
    30% { transform: translate(-2px, 2px); }
    100% { transform: translate(0); }
  }