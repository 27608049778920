/* Write your code here. */
.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f1f5f8;
  min-height: 100vh;
}

.faqs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  padding-top: 32px;
  padding-right: 12px;
  padding-bottom: 32px;
  padding-left: 12px;
  max-width: 550px;
}

@media screen and (min-width: 768px) {
  .faqs-container {
    width: 90%;
    max-width: 1140px;
    padding: 32px;
  }
}

.faqs-list {
  width: 100%;
  list-style-type: none;
  padding: 0px;
}

@media screen and (min-width: 768px) {
  .faqs-list {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .faqs-list {
    width: 80%;
  }
}

.heading {
  color: #3471ec;
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 64px;
  }
}