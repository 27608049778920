/* Write your code here. */
.faq-item {
  width: 100%;
  border: 1px solid #d7dae6;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 16px;
}

.question-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.question {
  color: #1470c5;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .question {
    font-size: 24px;
  }
}

.answer {
  color: #9aa5b1;
  font-family: 'Roboto';
  font-size: 16px;
}

@media (max-width: 576px) {
  .answer {
    font-size: 12px;
  }
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

@media (max-width: 576px) {
  .image {
    width: 15px;
    height: 15px;
  }
}

.horizontal-line {
  border: 1px solid #e4e7eb;
}